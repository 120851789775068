@media (max-width: 1235px) {
  .service-label {
    font-size: 16px;
  }
}

@media (max-width: 1085px) {
  .service-label {
    font-size: 14px;
  }
}

@media (max-width: 970px) {
  .service-label {
    font-size: 10px;
  }
}

@media (max-width: 745px) {
  .service-label {
    font-size: 8px;
  }
}

@media (min-width: 1235px) {
  .service-label {
    font-size: 20px;
  }
}
